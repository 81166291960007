<!-- Enter Temporary Credentials For Access -->
<template>
  <v-container
    class="mt-5"
    fill-height
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        md="10"
        sm="12"
      >
        <v-card>
          <v-row justify="center">
            <v-img
              :src="require(`@/assets/images/logos/SnowFlowLogo_Large.png`)"
              max-height="200"
              max-width="200"
              alt="logo"
              contain
              class="ma-5"
            ></v-img>
          </v-row>

          <v-card-title
            style="padding-top:40px"
            class="justify-center"
          >
            <v-img
              :src="serverConfig.logo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              eager
              class="app-logo me-3"
            ></v-img>
            <h3>
              WELCOME TO {{ serverConfig.name.toUpperCase() }}
            </h3>
          </v-card-title>

          <v-card-text>
            <p class="text--primary text-base text-center">
              Time to Supercharge 🚀 your Snowflake Instance
            </p>
          </v-card-text>
          <div>
            <v-card-actions>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row
                  class="ml-5 mr-5"
                >
                  <v-col
                    sm="12"
                  >
                    <v-alert
                      border="left"
                      colored-border
                      text
                      color="primary"
                    >
                      <strong>Give SnowFlow a full test drive completely free for 30 days</strong>
                    </v-alert>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="companyName"
                      :rules="nameRules"
                      :prepend-inner-icon="icons.mdiDomain"
                      outlined
                      dense
                      placeholder="Company Name"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="snowflakeAccountUrl"
                      :rules="urlRules"
                      :prepend-inner-icon="icons.mdiSnowflake"
                      outlined
                      dense
                      placeholder="Snowflake Account URL (https://<organization_name>-<account_name>.snowflakecomputing.com)"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="firstName"
                      :rules="nameRules"
                      :prepend-inner-icon="icons.mdiAccountOutline"
                      outlined
                      dense
                      placeholder="First Name"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="lastName"
                      :rules="nameRules"
                      :prepend-inner-icon="icons.mdiAccountOutline"
                      outlined
                      dense
                      placeholder="Last Name"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      :prepend-inner-icon="icons.mdiEmailOutline"
                      outlined
                      dense
                      placeholder="Email"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-select
                      v-model="timeZone"
                      :items="timeZones"
                      :rules="[v => !!v || 'Please Make a Selection']"
                      label="Default Server Timezone"
                      outlined
                      :prepend-inner-icon="icons.mdiClockTimeEightOutline"
                      dense
                      hide-details="auto"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="password"
                      :rules="[required,passwordValidator]"
                      :prepend-inner-icon="icons.mdiLockCheckOutline"
                      type="password"
                      outlined
                      dense
                      placeholder="Password"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-actions>
            <v-alert
              v-if="credentialsFailed"
              type="error"
              text
            >
              The Email/Password You Entered Is Not Correct. Please Try Again.
            </v-alert>
            <v-card-actions class="primary mt-3 pa-0">
              <v-btn
                :loading="loading"
                color="primary"
                block
                dark
                large
                :disabled="!valid"
                @click="submitForm"
              >
                SUBMIT
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable operator-linebreak */
import { passwordValidator, required } from '@/@core/utils/validation'
import { functions, httpsCallable } from '@/firebaseApp'
import { createAppUser } from '@/functions/authentication'
import { timeZoneList } from '@/functions/dates'
import { useRouter } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiClockTimeEightOutline, mdiDomain, mdiEmailOutline, mdiLockCheckOutline, mdiSnowflake } from '@mdi/js'
import serverConfig from '@serverConfig'
import { computed, ref } from '@vue/composition-api'

export default {
  setup() {
    const { router } = useRouter()
    const credentialsFailed = ref(false)
    const verificationLoading = ref(false)
    const companyName = ref(null)
    const snowflakeAccountUrl = ref(null)
    const firstName = ref(null)
    const lastName = ref(null)
    const email = ref(null)
    const timeZone = ref(null)
    const password = ref(null)
    const loading = ref(false)

    // Form Validation
    const form = ref(null)
    const valid = ref(false)
    const nameRules = [v => !!v || 'Name is required', v => (v && v.length <= 50) || 'Name must be less than 50 characters']
    const emailRules = [v => !!v || 'E-mail is required', v => /.+@.+..+/.test(v) || 'E-mail must be valid']
    const urlRules = [
      v =>
        // eslint-disable-next-line implicit-arrow-linebreak
        !!v || 'Account URL should be in the following format: https://<organization_name>-<account_name>.snowflakecomputing.com',
      v =>
        // eslint-disable-next-line implicit-arrow-linebreak
        /https+.+.com+/.test(v) || 'Account URL should be in the following format: https://<organization_name>-<account_name>.snowflakecomputing.com',
    ]

    const timeZones = computed(() => timeZoneList.filter(f => f.text !== 'Use Browser Local Time'))

    const snowflakeAccountId = computed(() => {
      const url = snowflakeAccountUrl.value
      const split = url.split('//')[1].split('.')

      return `${split[0]}.${split[1]}`
    })

    const submitForm = async () => {
      const isValid = form.value.validate()
      if (isValid) {
        loading.value = true

        // Create Account Data On Firebase - Retrieve New Account Id
        const createNewAccountFunction = httpsCallable(functions, 'createNewAppAccount')
        const newAccountOnFirestore = await createNewAccountFunction({
          accountName: companyName.value,
          email: email.value,
          timeZone: timeZone.value,
          snowflakeAccountUrl: snowflakeAccountUrl.value,
          snowflakeAccountId: snowflakeAccountId.value,
        })

        const { accountId, roleId } = newAccountOnFirestore.data

        // Create The App User
        const newUserPayload = {
          email: email.value,
          password: password.value,
          firstName: firstName.value,
          lastName: lastName.value,
          companyName: companyName.value,
          accountId,
          role: roleId,
          isNewAccount: true,
        }
        await createAppUser(newUserPayload)
        loading.value = false

        // Redirect to Account Setup
        router.push('/account-setup/system-account-setup')

        return 'SUCCESS'
      }

      return 'Success'
    }

    return {
      form,
      valid,
      nameRules,
      emailRules,
      urlRules,
      required,
      passwordValidator,
      companyName,
      snowflakeAccountUrl,
      firstName,
      lastName,
      email,
      timeZone,
      password,
      credentialsFailed,
      verificationLoading,
      serverConfig,
      timeZones,
      submitForm,
      loading,
      icons: {
        mdiEmailOutline,
        mdiDomain,
        mdiAccountOutline,
        mdiClockTimeEightOutline,
        mdiLockCheckOutline,
        mdiSnowflake,
      },
    }
  },
}
</script>
